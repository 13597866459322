// 制作公司印章

/* eslint-disable */
function onCanvasCompany(companyObj) {
  // 绘制圆形印章
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')

  // 关闭抗锯齿
  context.mozImageSmoothingEnabled = false
  context.webkitImageSmoothingEnabled = false
  context.msImageSmoothingEnabled = false
  context.imageSmoothingEnabled = false

  context.imageSmoothingQuality = 'high'
  const ratio = companyObj.ratio ? companyObj.ratio : 1
  const text = companyObj.text
  const companyName = companyObj.companyName
  const code = companyObj.code
  // 清除画布法一
  context.globalAlpha = 1
  context.fillStyle = 'rgba(255, 255, 255, 0)'
  // context.fillRect(0,0,canvas.width,canvas.height);

  let sizeWidth = companyObj.sealWidth
  let sizeHeight = companyObj.sealHeight
  console.log(companyObj.sealWidth)
  // sizeWidth = parseInt((sizeWidth * 28.346 * 96) / 72) // 96/72为后台缩放比例差
  // sizeHeight = parseInt((sizeHeight * 28.346 * 96) / 72)
  sizeWidth = Math.ceil((sizeWidth/5.8)*220)
  sizeHeight = Math.ceil((sizeHeight/5.8)*220)
  // 绘制印章边框
  canvas.width = (sizeWidth + 5) * ratio
  canvas.height = (sizeHeight + 5) * ratio
  // switch (companyObj.sealWidth) {
  //   case 3.8:
  //     sizeWidth = parseInt((sizeWidth * 28.346 * 96) / 55) // 96/72为后台缩放比例差
  //     sizeHeight = parseInt((sizeHeight * 28.346 * 96) / 55)
  //     canvas.width = 200
  //     canvas.height = 200
  // }
  const width = canvas.width / ratio / 2
  const height = canvas.height / ratio / 2
  context.scale(ratio, ratio)
  context.lineWidth = 5
  context.strokeStyle = '#f00'
  context.beginPath()
  context.arc(width, height, sizeHeight / 2, 0, Math.PI * 2) // 宽、高、半径
  // context.arc(width, height, sizeHeight, 0, Math.PI * 2) // 宽、高、半径
  context.stroke()

  // 画五角星
  if (companyObj.radioFlag == 1) {
    if (companyObj.sealWidth == '3.8') {
      create5star(context, width, height, 16, '#f00', 0)
      // create5star(context, width, height, 16 * 2, '#f00', 0)
    } else if (companyObj.sealWidth == '4.0') {
      create5star(context, width, height, 16, '#f00', 0)
      // create5star(context, width, height, 16 * 2, '#f00', 0)
    } else if (companyObj.sealWidth == '4.2') {
      create5star(context, width, height, 18, '#f00', 0)
      // create5star(context, width, height, 18 * 2, '#f00', 0)
    } else if (companyObj.sealWidth == '5.8') {
      create5star(context, width, height, 30, '#f00', 0)
      // create5star(context, width, height, 30 * 2, '#f00', 0)
    } else {
      create5star(context, width, height, 25, '#f00', 0)
      // create5star(context, width, height, 25 * 2, '#f00', 0)
    }
  }
  // 公章类型
  if (text) {
    // 绘制印章名称
    context.font = 'bold 10px ybst'
    context.textBaseline = 'middle' // 设置文本的垂直对齐方式
    context.textAlign = 'center' // 设置文本的水平对对齐方式
    context.lineWidth = 1
    context.fillStyle = '#f00'
    context.save()
    if (companyObj.sealWidth == '3.8') {
      context.font = 'bold 8px ybst'
      context.translate(width, height + 40) // 平移到此位置,
    } else if (companyObj.sealWidth == '4.0') {
      context.font = 'bold 10px ybst'
      context.translate(width, height + 42) // 平移到此位置,
    } else if (companyObj.sealWidth == '5.8') {
      context.font = 'bold 12px ybst'
      context.translate(width, height + 65) // 平移到此位置,
    } else {
      context.translate(width, height + 48) // 平移到此位置,
    }
    context.scale(1, 2) // 伸缩要先把远点平移到要写字的位置，然后在绘制文字
    context.fillText(text, 0, 0) // 原点已经移动
    context.restore()
  }

  context.font = 'bold 10px ybst'
  context.textBaseline = 'middle' // 设置文本的垂直对齐方式
  context.textAlign = 'center' // 设置文本的水平对对齐方式
  context.fillStyle = '#f00'

  if (companyName) {
    // 绘制印章单位
    context.translate(width, height) // 平移到此位置,
    var count = companyName.length // 字数
    var angle = (4 * Math.PI) / (3 * (count - 1)) // 字间角度
    var chars = companyName.split('')
    context.textBaseline = 'middle' // 设置文本的垂直对齐方式
    context.textAlign = 'center' // 设置文本的水平对对齐方式
    context.fillStyle = '#f00'
    var c
    if (companyObj.sealWidth == '3.8') {
      context.font = 'bold 10px ybst'
      // context.font = 'bold 20px ybst'
      for (var i = 0; i < count; i++) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6)
        } else {
          context.rotate(angle)
        }
        // 90 79
        context.save()
        context.translate(62, 0) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1, 2) // 伸缩画布，实现文字的拉长
        scaleFont(context, count)
        context.fillText(c, 0, 5) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '4.2') {
      context.font = 'bold 12px ybst'
      // context.font = 'bold 24px ybst'
      for (var i = 0; i < count; i++) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6)
        } else {
          context.rotate(angle)
        }
        // 90 79
        context.save()
        context.translate(66, 0) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1, 2) // 伸缩画布，实现文字的拉长
        // context.scale(0.5, 2) // 伸缩画布，实现文字的拉长
        // context.scale(1, 0.4)
        scaleFont(context, count)
        context.fillText(c, 0, 3) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '4.0') {
      context.font = 'bold 12px ybst'
      // context.font = 'bold 24px ybst'
      for (var i = 0; i < count; i++) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6)
        } else {
          context.rotate(angle)
        }
        // 90 79
        context.save()
        context.translate(64, 0) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1, 2) // 伸缩画布，实现文字的拉长
        scaleFont(context, count)
        context.fillText(c, 0, 5) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '5.8') {
      context.font = 'bold 19px ybst'
      // context.font = 'bold 38px ybst'
      for (var i = 0; i < count; i++) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == 0) {
          context.rotate((5 * Math.PI) / 6)
        } else {
          context.rotate(angle)
        }
        // 90 79
        context.save()
        context.translate(64, 10) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1, 2) // 伸缩画布，实现文字的拉长
        scaleFont(context, count)
        context.fillText(c, -10, -10) // 此点为字的中心点
        context.restore()
      }
    }
  }

  // 设置
  // 设置画布为最初的位置为原点，旋转回平衡的原位置，用于清除画布
  context.rotate(-Math.PI / 6)
  context.translate(0 - canvas.width / ratio / 2, 0 - canvas.height / ratio / 2)

  // 绘制编码
  if (code) {
    context.translate(width, height) // 平移到此位置,
    context.font = 'bold 10px ybst'
    // context.font = 'bold 20px ybst'
    context.textBaseline = 'middle' // 设置文本的垂直对齐方式
    context.textAlign = 'center' // 设置文本的水平对对齐方式
    context.fillStyle = '#f00'
    var count = code.length // 字数
    var angle = (4 * Math.PI) / (8 * (count - 1)) // 字间角度
    var chars = code.split('')
    var c
    if (companyObj.sealWidth == '3.8') {
      context.font = 'bold 8px ybst'
      // context.font = 'bold 16px ybst'
      for (var i = count - 1; i >= 0; i--) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == count - 1) {
          context.rotate((234 * Math.PI) / 180)
        } else {
          context.rotate(angle)
        }
        context.save()
        // context.translate(-60, 0)
        // context.translate(66, 20) // 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1,2);//伸缩画布，实现文字的拉长
        context.fillText(c, 10, 60) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '4.2') {
      for (var i = count - 1; i >= 0; i--) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == count - 1) {
          context.rotate((233 * Math.PI) / 180)
        } else {
          context.rotate(angle)
        }
        context.save()
        // context.translate(-60, 0)
        // context.translate(66, 20);// 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1,2);//伸缩画布，实现文字的拉长
        context.fillText(c, 10, 68) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '4.0') {
      for (var i = count - 1; i >= 0; i--) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == count - 1) {
          context.rotate((234 * Math.PI) / 180)
        } else {
          context.rotate(angle)
        }
        context.save()
        // context.translate(-60, 0)
        // context.translate(66, 20);// 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1,2);//伸缩画布，实现文字的拉长
        context.fillText(c, 10, 63) // 此点为字的中心点
        context.restore()
      }
    }
    if (companyObj.sealWidth == '5.8') {
      for (var i = count - 1; i >= 0; i--) {
        c = chars[i] // 需要绘制的字符 　　　　　　　　　//绕canvas的画布圆心旋转
        if (i == count - 1) {
          context.rotate((232 * Math.PI) / 180)
        } else {
          context.rotate(angle)
        }
        context.save()
        // context.translate(-90, 0)
        // context.translate(66, 20);// 平移到此位置,此时字和x轴垂直，公司名称和最外圈的距离
        context.rotate(Math.PI / 2) // 旋转90度,让字平行于x轴
        // context.scale(1,2);//伸缩画布，实现文字的拉长
        context.fillText(c, 10, 95) // 此点为字的中心点
        context.restore()
      }
    }
  }

  // 绘制五角星
  /**
   * 创建一个五角星形状. 该五角星的中心坐标为(sx,sy),中心到顶点的距离为radius,rotate=0时一个顶点在对称轴上
   * rotate:绕对称轴旋转rotate弧度
   */
  function create5star(context, sx, sy, radius, color, rotato) {
    context.save()
    context.fillStyle = color
    context.translate(sx, sy) // 移动坐标原点
    context.rotate(Math.PI + rotato) // 旋转
    context.beginPath() // 创建路径
    var x = Math.sin(0)
    var y = Math.cos(0)
    const dig = (Math.PI / 5) * 4
    for (let i = 0; i < 5; i++) {
      // 画五角星的五条边
      var x = Math.sin(i * dig)
      var y = Math.cos(i * dig)
      context.lineTo(x * radius, y * radius)
    }
    context.closePath()
    context.stroke()
    context.fill()
    context.restore()
  }

  // context.scale(0.5, 0.5)
  return canvas.toDataURL('image/png', 1.0)
  // context.globalCompositeOperation = "source-in"; //全局合成操作
}

function scaleFont(context, count) {
  if (count <= 20) {
    context.scale(1, 2)
  } else if (count >= 20 && count <= 30) {
    context.scale(0.7, 2)
  } else if (count >= 30 && count <= 40) {
    context.scale(0.5, 2)
  } else if (count >= 40) {
    context.scale(0.4, 2)
  }
}

export default onCanvasCompany
