<template>
  <div class="view-addSeal">
    <div class="content" v-if="!treeShow">
      <van-form
        class="sealForm"
        @submit="onSubmit"
        @failed="onFailed"
        v-if="type == 1"
      >
        <van-field
          class="fieldBold"
          v-model="ruleForm.sealName"
          ref="sealName"
          required
          maxlength="10"
          name="sealName"
          label="印章名称"
          placeholder="请输入印章名称"
          :rules="[{ required: true, message: '请输入印章名称' }]"
        />
        <div class="title">
          <span>印章样式</span>
        </div>
        <ul class="sealList">
          <li
            v-for="(item, index) in sealImgList"
            :key="index"
            :class="item.sealType === 4 ? 'ellipse' : ''"
          >
            <div
              class="seal"
              :class="{ active: ruleForm.sealType == item.sealType }"
              @click="handleSelectSealType(item.sealType)"
            >
              <img :src="item.value" alt="" />
            </div>
            <div class="name">{{ item.label }}</div>
          </li>
        </ul>
        <div class="title">
          <span>设置印章规格</span>
        </div>
        <van-field
          v-show="ruleForm.sealType !== 4"
          readonly
          clickable
          :value="
            ruleForm.diametre != null ? columns[ruleForm.diametre].text : ''
          "
          ref="diametre"
          required
          name="diametre"
          label="印章直径"
          placeholder="请选择印章直径"
          :rules="[{ required: true, message: '请选择印章直径' }]"
          @click="showPicker = true"
        />
        <van-field
          readonly
          v-model="ruleForm.siding"
          required
          name="siding"
          label="圆边宽"
          placeholder="圆边宽"
        />
        <div class="title">
          <span>设置印章文字</span>
        </div>
        <van-field
          readonly
          v-model="userInfo.comName"
          label="环绕文字"
          placeholder="环绕文字"
        />
        <van-field
          v-show="ruleForm.sealType === 4"
          v-model="ruleForm.centerWord"
          maxlength="20"
          name="rankWord"
          label="中间横排文字"
          placeholder="请输入中间横排文字（非必填）"
        />
        <van-field
          v-if="ruleForm.sealType == 0"
          v-model="ruleForm.surroundWord"
          :maxlength="30 - userInfo.comName.length"
          @focus="focusSurroundWord"
          name="surroundWord"
          label="分公司名称"
          placeholder="请输入分公司名称（非必填）"
        />
        <van-field
          v-model="ruleForm.rankWord"
          maxlength="8"
          name="rankWord"
          label="下方横排文字"
          placeholder="请输入下方横排文字（非必填）"
        />
        <van-field
          v-show="ruleForm.sealType !== 4"
          v-model="ruleForm.infoCode"
          maxlength="18"
          name="infoCode"
          ref="infoCode"
          label="信息编码"
          placeholder="请输入印章上13-18位数字或字母（非必填）"
          :rules="[
            { validator: checkAge, message: '请输入印章上13-18位数字或字母' },
          ]"
        />
        <div class="view-button">
          <van-button plain type="info" @click="back">
            返回
          </van-button>
          <van-button type="info" native-type="submit">
            下一步
          </van-button>
        </div>
      </van-form>
      <div class="sealPreview" v-if="type == 2">
        <div class="title">样章预览</div>
        <div class="previewForm">
          <img :src="sealImg" alt="" />
        </div>
        <div class="title">添加印章持有人</div>
        <ul class="holderList">
          <!-- <li
            class="item"
            v-for="(person, index) in defaultAdminList"
            :key="index + 'p'"
          >
            {{ person.userName ? person.userName : person.telephone }}
          </li> -->
          <li
            class="item"
            v-for="(person, index) in ruleForm.sealOwnerList"
            :key="index"
          >
            {{ person.sealOwnerName ? person.sealOwnerName : person.telephone }}
            <img
              v-if="person.defaultAuth === 0"
              @click="deleteSealOwner(index)"
              src="@/assets/imgs/sealManage/delHolder.png"
              alt=""
              class="del"
            />
          </li>
          <div class="add" @click="addholder">
            <img src="@/assets/imgs/sealManage/addHolder.png" alt="" />
          </div>
        </ul>
        <div class="view-button">
          <van-button plain type="info" @click="type = 1">
            上一步
          </van-button>
          <van-button type="info" @click="makeSealOnline">
            制作完成
          </van-button>
        </div>
      </div>
      <van-popup v-model="showPicker" position="bottom" round>
        <van-picker
          show-toolbar
          title="印章直径"
          :columns="columns"
          :default-index="ruleForm.diametre"
          @confirm="onConfirmDiametre"
          @cancel="showPicker = false"
        />
      </van-popup>
    </div>
    <companyFriend
      :show="treeShow"
      :type="0"
      @roleCallback="addSealRoleAuth"
      @onConfirmClose="treeShow = false"
      @onClose="treeShow = false"
    ></companyFriend>
  </div>
</template>

<script>
  import companyFriend from '@/components/selectPerson/companyFriend.vue'
  import makeSeal from '@/utils/makeSealNew'
  import FXQ from '@/lib/drawSeal'
  import { drawEllipseSeal } from '@/lib/drawEllipseSeal'
  import sealApi from '@/api/seal'
  import { mapState } from 'vuex'

  export default {
    components: {
      companyFriend,
    },
    data() {
      return {
        treeShow: false,
        type: 1, // 1 表单 // 2 预览
        sealId: null,
        ruleForm: {
          sealName: null, // 印章名称
          sealType: 0, // 印章样式 0-企、事业公章 1-专用章-带五角星 2-专用章-不带五角星 3-共青团 4-椭圆章
          diametre: 2, // 印章直径0-38mm 1-40mm 2-42mm 3-58mm
          siding: '1.2mm', // 圆边宽
          surroundWord: null, // 环绕文字
          rankWord: null, // 横排文字
          infoCode: null, // 信息编码
          sealOwnerList: [], // 前端显示用
          sealSource: 0,
          uploadDiametre: '',
          uploadSealType: '',
          sealStatus: '',
          // 中间横排文字
          centerWord: '',
        },
        sealImgList: [
          {
            // eslint-disable-next-line global-require
            value: require('@/assets/imgs/sealManage/seal1.png'),
            label: '公章',
            sealType: 0,
          },
          {
            // eslint-disable-next-line global-require
            value: require('@/assets/imgs/sealManage/seal2.png'),
            label: '专用章-带五角星',
            sealType: 1,
          },
          {
            // eslint-disable-next-line global-require
            value: require('@/assets/imgs/sealManage/seal3.png'),
            label: '专用章不带五角星',
            sealType: 2,
          },
          {
            // eslint-disable-next-line global-require
            value: require('@/assets/imgs/sealManage/ellipse_seal.png'),
            label: '椭圆章',
            sealType: 4,
          },
        ],
        columns: [
          {
            text: '38mm*38mm',
            value: 0,
          },
          {
            text: '40mm*40mm',
            value: 1,
          },
          {
            text: '42mm*42mm',
            value: 2,
          },
          // {
          //   text: '58mm*58mm',
          //   value: 3,
          // },
        ],
        showPicker: false,
        sealImg: null, // 预览图
        sealImgBig: null, // 预览大图
        defaultAdminList: [],
      }
    },
    computed: {
      ...mapState({
        userInfo: state => state.user.userInfo,
      }),
      userIdList() {
        return this.ruleForm.sealOwnerList.map(e => e.sealUserId)
      },
    },
    created() {
      this.sealId = this.$route.query.id ? this.$route.query.id : null
      if (this.sealId) {
        this.getSealDetail()
      } else {
        this.getDefaultAdminList()
      }
    },
    methods: {
      handleSelectSealType(value) {
        this.ruleForm.sealType = value
        this.ruleForm.surroundWord = null
        if (this.ruleForm.sealType === 4) {
          this.ruleForm.siding = '1.0mm'
        }
      },
      focusSurroundWord() {
        if (30 - this.userInfo.comName.length <= 0) {
          this.$toast('企业+分公司名称不能超过30个字')
        }
      },
      checkAge(val) {
        if (val.length) {
          if (val.length >= 13 && val.length <= 18) {
            return /^[\da-zA-Z]+$/g.test(val)
          }
          return false
        }
        return true
      },
      deleteSealOwner(index) {
        this.ruleForm.sealOwnerList.splice(index, 1)
      },
      addholder() {
        this.treeShow = true
      },
      addSealRoleAuth(userList) {
        console.log(userList)
        userList.forEach(item => {
          if (
            !this.ruleForm.sealOwnerList.find(
              seal => seal.sealUserId === item.uid
            )
          ) {
            this.ruleForm.sealOwnerList.push({
              sealUserId: item.uid,
              sealOwnerName: item.name,
              telephone: item.telephone,
              defaultAuth: 0,
            })
          }
        })
      },
      makeSealOnline() {
        const obj = {
          ...this.ruleForm,
          sealImg: this.sealImg,
          sealImgBig: this.sealImgBig,
          infoCode: this.ruleForm.infoCode ? this.ruleForm.infoCode : null,
          userIdList: this.userIdList,
        }

        sealApi.makeSealOnline(obj).then(res => {
          window.history.back()
        })
      },
      getDefaultAdminList() {
        sealApi.getDefaultAdminList().then(res => {
          this.defaultAdminList = res
          this.ruleForm.sealOwnerList = res.map(e => {
            return {
              sealUserId: e.userId,
              sealOwnerName: e.userName,
              telephone: e.telephone,
              defaultAuth: 1,
            }
          })
        })
      },
      getSealDetail() {
        sealApi
          .getSealDetail({
            sealId: this.sealId,
          })
          .then(res => {
            this.ruleForm = Object.assign(this.ruleForm, res)
          })
      },
      onConfirmDiametre(value) {
        this.ruleForm.diametre = value.value
        this.showPicker = false
      },
      back() {
        window.history.back()
      },
      onFailed(errorInfo) {
        console.log('failed', errorInfo)
        this.$refs[errorInfo.errors[0].name].$el.scrollIntoView()
      },
      onSubmit() {
        this.type = 2
        const {
          sealType,
          diametre,
          infoCode,
          rankWord,
          surroundWord,
          centerWord,
        } = this.ruleForm
        const sealWidth = [3.8, 4.0, 4.2, 5.8]

        if (this.ruleForm.sealType === 4) {
          // this.sealImg = FXQ.companyEllipse({
          //   companyName: surroundWord
          //     ? `${this.userInfo.comName}${surroundWord}`
          //     : this.userInfo.comName,
          //   text: centerWord ? centerWord.toString() : '',
          //   code: rankWord ? rankWord.toString() : '',
          //   cType: 0,
          //   fType: 0,
          // })

          this.sealImg = drawEllipseSeal({
            companyName: surroundWord
              ? `${this.userInfo.comName}${surroundWord}`
              : this.userInfo.comName,
            code: centerWord ? centerWord.toString() : '',
            text: rankWord ? rankWord.toString() : '',
            ratio: 1,
          })
          this.sealImgBig = drawEllipseSeal({
            companyName: surroundWord
              ? `${this.userInfo.comName}${surroundWord}`
              : this.userInfo.comName,
            code: centerWord ? centerWord.toString() : '',
            text: rankWord ? rankWord.toString() : '',
            ratio: 3,
          })
        } else {
          this.sealImg = makeSeal({
            radioFlag: sealType === 2 ? 0 : 1, // 是否绘制五角星
            sealWidth: sealWidth[diametre],
            code: infoCode,
            text: rankWord,
            companyName: surroundWord
              ? `${this.userInfo.comName}${surroundWord}`
              : this.userInfo.comName,
            sealHeight: sealWidth[diametre],
            ratio: 1,
          })
          this.sealImgBig = makeSeal({
            radioFlag: sealType === 2 ? 0 : 1, // 是否绘制五角星
            sealWidth: sealWidth[diametre],
            code: infoCode,
            text: rankWord,
            companyName: surroundWord
              ? `${this.userInfo.comName}${surroundWord}`
              : this.userInfo.comName,
            sealHeight: sealWidth[diametre],
            ratio: 3,
          })
        }
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-addSeal {
    padding-bottom: 66px;
    .content {
      padding: 0 16px;
      padding-bottom: 70px;
    }
    /deep/ .sealForm {
      /deep/ .van-field {
        padding: 0;
        flex-wrap: wrap;
        &:after {
          display: none;
        }
        /deep/ .van-field__label {
          width: 100%;
          height: 44px;
          margin: 0;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #111a34;
          line-height: 20px;
        }
        /deep/ .van-field__value {
          width: 100%;
          flex: 0 1 auto;
          /deep/ .van-field__body {
            height: 48px;
            border-bottom: 1px solid #f5f5f5;
            /deep/ input {
              font-size: 14px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: left;
              color: #111a34;
              line-height: 20px;
            }
          }
        }
      }
      /deep/ .fieldBold {
        /deep/ .van-field__label {
          font-size: 16px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          text-align: left;
          color: #111a34;
          line-height: 22px;
        }
      }
      /deep/ .van-cell--required {
        &:before {
          display: none;
        }
        /deep/ .van-field__label {
          &:after {
            color: #f15643;
            content: '*';
          }
        }
      }
      .title {
        display: flex;
        align-items: center;
        padding-top: 16px;
        span {
          display: flex;
          align-items: center;
          padding: 0 14px;
          height: 27px;
          background: #8a9ab4;
          border-radius: 1px 62px 62px 1px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Medium;
          font-weight: bold;
          color: #ffffff;
        }
        &:before {
          content: '';
          display: inline-block;
          width: 3px;
          height: 27px;
          background: #8a9ab4;
          border-radius: 1px;
          margin-right: 2px;
        }
      }
      .sealList {
        padding-top: 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        li {
          .seal {
            position: relative;
            width: 103px;
            height: 104px;
            border: 1px solid #dcdee0;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 11px;
            img {
              width: 83px;
              height: 83px;
            }
          }
          &.ellipse {
            .seal {
              height: 104px;
            }
            img {
              height: 63px;
            }
          }
          &:nth-of-type(n + 4) {
            margin-top: 16px;
          }
          .active {
            border: 1px solid rgba(22, 118, 255, 0.4);
            &:after {
              content: '';
              display: inline-block;
              width: 25px;
              height: 23px;
              position: absolute;
              right: 0;
              bottom: -1px;
              background: url(../../../assets/imgs/sealManage/active.png)
                no-repeat center;
              background-size: 100%;
            }
          }
          .name {
            font-size: 12px;
            font-family: PingFangSC, PingFangSC-Regular;
            font-weight: 400;
            text-align: center;
            color: #404c56;
            line-height: 17px;
          }
        }
      }
    }
    /deep/ .van-picker__toolbar {
      height: 56px;
      background: #f4f5f6;
      /deep/ .van-picker__cancel {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #666666;
        line-height: 20px;
      }
      /deep/ .van-picker__confirm {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: right;
        color: #1676ff;
        line-height: 20px;
      }
      /deep/ .van-picker__title {
        font-size: 20px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        text-align: center;
        color: #41485d;
        line-height: 28px;
      }
    }
    /deep/ .van-picker__columns {
      /deep/ .van-picker-column__item {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #8a9ab4;
        line-height: 22px;
      }
      /deep/ .van-picker-column__item--selected {
        font-size: 18px;
        font-family: PingFangSC, PingFangSC-Medium;
        font-weight: bold;
        text-align: center;
        color: #2f86f6;
        line-height: 25px;
      }
      /deep/ .van-picker__frame {
        border-top: 1px solid rgba(64, 76, 86, 0.3);
        border-bottom: 1px solid rgba(64, 76, 86, 0.3);
      }
    }
    /deep/ .van-field--error .van-field__control,
    /deep/ .van-field--error .van-field__control::placeholder {
      color: #c1c4cd;
      -webkit-text-fill-color: #c1c4cd;
    }
    .sealPreview {
      padding-top: 16px;
      .title {
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #111a34;
        line-height: 22px;
      }
      .previewForm {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px auto;
        width: 305px;
        height: 304px;
        border: 1px dashed #c1c4cd;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .holderList {
        padding-top: 16px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 87px;
          height: 32px;
          background: rgba(22, 118, 255, 0.1);
          border-radius: 16px;
          margin-right: 16px;
          margin-bottom: 16px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: left;
          color: #1676ff;
          line-height: 20px;
          .del {
            width: 20px;
            margin-left: 3px;
          }
        }
        .add {
          height: 27px;
          margin-bottom: 16px;
          display: flex;
          align-items: center;
          img {
            width: 16px;
          }
        }
      }
    }
  }
</style>
