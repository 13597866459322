/* eslint-disable */
function drawEllipseSeal({ companyName, code, text, ratio }) {
  return createSeal2('canvas', companyName, code, text, ratio)
}

function createSeal2(id, company, code, name, ratio = 1) {
  let canvas = document.createElement('canvas')
  canvas.width = 204 * ratio
  canvas.height = 133 * ratio
  let context = canvas.getContext('2d')
  context.scale(ratio, ratio)
  context.strokeStyle = 'red' // 设置文本颜色
  context.textBaseline = 'middle' // 设置文本的垂直对齐方式
  context.textAlign = 'center' // 设置文本的水平对对齐方式
  context.lineWidth = 2 // 椭圆1宽度
  // 3个参数： 左边距 上边据 宽度 椭圆扁度
  // width 198 height 129
  BezierEllipse4(context, 101, 66, 99, 65) // 椭圆1

  // 绘制印章类型
  context.font = 'bolder 12px SimSun'
  context.lineWidth = 1
  context.fillStyle = '#f00'
  context.fillText(code, canvas.width / ratio / 2 + 2, canvas.height / ratio / 2 + 10, 110)
  context.font = 'bolder 14px FangSong'
  context.fillText(name, canvas.width / ratio / 2 + 2, canvas.height / ratio / 2 + 40)
  context.save()
  // 绘制中文
  let ccircle = {
    x: canvas.width / ratio / 2 - 10,
    y: canvas.height / ratio / 2 + 8,
    radius: 70,
  }

  let cstartAngle = 200 // 控制字符起始位置度数
  let cendAngle = -20 // 首位字符相隔度数

  let cradius = ccircle.radius // 圆的半径
  let cangleDecrement = (cstartAngle - cendAngle) / (company.length - 1) // 每个字母占的弧度
  context.font = '14px FangSong'
  let cratioX = 82 / ccircle.radius // 横轴缩放比率
  let cratioY = 55 / ccircle.radius // 纵轴缩放比率
  // 进行缩放（均匀压缩）
  context.scale(cratioX, cratioY)
  var cindex = 0
  for (var cindex = 0; cindex < company.length; cindex++) {
    context.save()
    //   context.restore();
    context.beginPath()
    // 绘制点
    context.translate(
      ccircle.x + Math.cos((Math.PI / 180) * cstartAngle) * cradius - 6,
      ccircle.y - Math.sin((Math.PI / 180) * cstartAngle) * cradius + 14
    )
    context.rotate(Math.PI / 2 - (Math.PI / 180) * cstartAngle) // Math.PI/2为旋转90度  Math.PI/180*X为旋转多少度
    context.fillText(company.charAt(cindex), 0, 0)
    context.strokeText(company.charAt(cindex), 0, 0)
    cstartAngle -= cangleDecrement
    context.restore()
  }
  return canvas.toDataURL('image/png', 1.0)
}
function BezierEllipse4(ctx, x, y, a, b) {
  let k = 0.5522848
  let ox = a * k // 水平控制点偏移量
  let oy = b * k // 垂直控制点偏移量</p> <p>
  ctx.beginPath()
  // 从椭圆的左端点开始顺时针绘制四条三次贝塞尔曲线
  ctx.moveTo(x - a, y)
  ctx.bezierCurveTo(x - a, y - oy, x - ox, y - b, x, y - b)
  ctx.bezierCurveTo(x + ox, y - b, x + a, y - oy, x + a, y)
  ctx.bezierCurveTo(x + a, y + oy, x + ox, y + b, x, y + b)
  ctx.bezierCurveTo(x - ox, y + b, x - a, y + oy, x - a, y)
  ctx.closePath()
  ctx.stroke()
}

export { drawEllipseSeal }
